<!-- src/components/common/ProgressBar.vue -->
<template>
  <div class="row">
    <div class="col-12">
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{ width: progress + '%' }"
          :aria-valuenow="progress"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.progress {
  height: 20px;
  background-color: #e9ecef;
  border-radius: 10px;
}

.progress-bar {
  background-color: #00a0e1;
}
</style>
