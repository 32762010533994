// src/composables/useStepNavigation.js
import { ref } from 'vue';

export function useStepNavigation(stepConfig) {
  // Inicializamos los pasos y el paso actual usando la configuración
  const steps = ref([...stepConfig]);
  const currentStep = ref({ ...stepConfig[0], buttons: [] });

  /**
   * Actualiza el paso actual según el número de paso.
   * @param {Object} payload - Objeto con la propiedad step.
   */
  function updateStepProgress({ step }) {
    const stepData = steps.value.find((s) => s.step === step);
    if (stepData) {
      // Mantenemos los botones previos o reiniciamos según se necesite
      currentStep.value = { ...stepData, buttons: currentStep.value.buttons || [] };
    }
  }

  /**
   * Actualiza el array de botones basado en los filtros seleccionados.
   * Se espera que `selectedFilters` sea un objeto con propiedades:
   * vehicle, brand, model y version.
   * @param {number} index - Índice del paso a partir del cual se actualiza.
   * @param {Object} selectedFilters - Objeto con la selección de filtros.
   */
  function updateStepButtons(index, selectedFilters) {
    const buttons = [];
    if (index <= 0 && selectedFilters.vehicle) {
      buttons.push({ vehicle: selectedFilters.vehicle.name });
    }
    if (index <= 1 && selectedFilters.brand) {
      buttons.push({ brand: selectedFilters.brand.name });
    }
    if (index <= 2 && selectedFilters.model) {
      buttons.push({ model: selectedFilters.model.name });
    }
    if (index <= 3 && selectedFilters.version) {
      buttons.push({ version: selectedFilters.version.name });
    }
    currentStep.value.buttons = buttons;
  }

  /**
   * Resetea el paso actual a partir del índice indicado.
   * Por ejemplo, si se elimina un filtro en el paso 2 (índice 1),
   * se reinicia el proceso a partir del paso 2.
   * @param {number} index - Índice (basado en 0) del filtro reseteado.
   */
  function resetSteps(index) {
    const targetStep = steps.value.find((s) => s.step === index + 1);
    if (targetStep) {
      currentStep.value = { ...targetStep, buttons: [] };
    }
  }

  return {
    currentStep,
    steps,
    updateStepProgress,
    updateStepButtons,
    resetSteps,
  };
}
