<template>
  <div class="important-text">
    <p>
      <strong>Importante:</strong> <br />
      <strong>Los precios de las unidades 0 Km. y usados están expresados en miles.</strong>
      Se componen de IVA y Valor Básico. Los valores de automotores usados
      detallados en la presente Guía, se refieren a unidades en condiciones
      satisfactorias de uso. Dichos valores rigen solamente para Capital
      Federal y Gran Buenos Aires, pudiendo sufrir variaciones en más o menos,
      de acuerdo a las condiciones operativas existentes en las distintas
      zonas del país y están expresados en miles. La encuesta de precios es
      relevada permanentemente por la Comisión de Valuación de Vehículos de
      ACARA, la cual integra representantes de distintos concesionarios de
      marca que operan en el mercado. Dicha Comisión, en el transcurso de una
      reunión que celebra a la finalización de cada mes, acuerda las
      valorizaciones para cada marca, modelo y año, sobre la base del desenvolvimiento
      comercial observado durante el último periodo mensual. A efectos de cualquier
      tipo de valorización realizada en base a los datos contenidos en la presente
      publicación, considérese como una guía de precios, pudiendo existir diferencias
      respecto al tipo de vehículo y/o operación en cuestión. Los valores de 0 km pueden
      incluir bonificaciones de las terminales. Queda terminantemente prohibida la reproducción
      total o parcial de esta información.
    </p>
  </div>
</template>

<script>
export default {
  name: "ImportantText",
};
</script>

<style scoped>
.important-text {
  margin-top: 50px;
}
</style>
