<template>
  <div class="general-view">
    <div v-if="loading" class="loading-indicator">
      <LoadingIndicator />
    </div>
    <div v-else>
      <div class="carousel-container">
        <CarouselComponent :slides="allViews.sliders" />
      </div>
      <BlockComponent
        v-for="block in allViews.blocks"
        :key="block.id"
        :block="block"
      />
      <ServicesComponent v-if="allViews.cards.length > 0" :services="allViews.cards" />
      <ButtonLinkComponent v-if="allViews.buttonLinks.length > 0" :buttonLinks="allViews.buttonLinks" />
      <NovedadComponent v-if="allViews.articles.length > 0" :articles="allViews.articles" />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useImageLoader } from "@/composables/useImageLoader";
import { fetchViewByUrl } from "@/services/api/view.js";
import NovedadComponent from "@/components/novedades/Novedad.vue";
import CarouselComponent from "@/components/carousel/CarouselComponent.vue";
import BlockComponent from "@/components/block/BlockComponent.vue";
import ButtonLinkComponent from "@/components/bottonLink/ButtonLinkComponent.vue";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";
import ServicesComponent from "@/components/servicios/Servicio.vue";

const { loading, allViews, loadImages } = useImageLoader();

onMounted(async () => {
  await loadImages(fetchViewByUrl);

});
</script>

<style scoped>
.general-view {
  margin: 0 auto;
  position: relative;
}

.carousel-container {
  position: relative;
}
</style>
