<template>
  <div class="usuarios-view">
    <header class="header">
      <h1>ADMINISTRADOR</h1>
    </header>
    <div class="table-container">
      <div
        class="table-header d-flex justify-content-between align-items-center"
      >
        <h2>Usuarios</h2>
        <button
          @click="openUserRegistrationModal(false)"
          class="btn btn-primary btn-new"
        >
          NUEVO
        </button>
      </div>
      <div class="mb-3">
        <input
          type="text"
          v-model="searchTerm"
          class="form-control"
          placeholder="Buscar..."
        />
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>CUIT</th>
              <th>DNI</th>
              <th>Email</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.name || "N/A" }}</td>
              <td>{{ user.lastname || "N/A" }}</td>
              <td>{{ user.cuit || "N/A" }}</td>
              <td>{{ user.dni || "N/A" }}</td>
              <td>{{ user.email || "N/A" }}</td>
              <td class="options text-center">
                <div class="d-flex flex-wrap justify-content-center gap-2">
                  <!-- Botón para ver detalles -->
                  <button
                    @click="openViewsModal(user)"
                    class="btn btn-sm btn-toggle-views"
                  >
                    <i class="fas fa-eye"></i>
                  </button>

                  <!-- Botón para abrir el modal de registro de usuario -->
                  <button
                    @click="openUserRegistrationModal(true, user)"
                    class="btn btn-sm btn-register-user"
                  >
                    <i class="fas fa-user-plus"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="users.length === 0" class="no-results">
        No se encontraron usuarios.
      </div>

      <PaginationComponent
        :currentPage="currentPage"
        :lastPage="lastPage"
        :hasMorePages="hasMorePages"
        @pageChange="loadUsersWithSearch"
        class="pagination-container"
      />
    </div>

    <!-- Modal de Vistas -->
    <ViewsModal
      :isOpen="modalOpen"
      :user="selectedUser"
      @close="handleModalClose"
      @success="handleModalSuccess"
    />
    <!-- Modal de Registro de Usuario -->
    <UserRegistrationModal
      v-if="showUserRegistrationModal"
      :isEditing="isEditing"
      :isVisible="showUserRegistrationModal"
      :initialData="selectedUser"
      @close="closeUserRegistrationModal"
      @success="handleModalSuccess"
    />
  </div>
</template>

<script>
import { ref, onMounted, watch, nextTick } from "vue";
import { fetchUsers } from "@/services/api/user.js";
import usePagination from "@/composables/usePagination";
import ViewsModal from "@/components/administrador/ViewsModal.vue";
import PaginationComponent from "@/components/common/PaginationComponent.vue";
import UserRegistrationModal from "@/components/administrador/UserRegistrationModal.vue";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";
import { debounce } from "lodash";

export default {
  name: "AdministradorUsuariosView",
  components: {
    ViewsModal,
    PaginationComponent,
    UserRegistrationModal,
  },
  setup() {
    const searchTerm = ref("");
    const pagination = usePagination(fetchUsers);
    const { currentPage, lastPage, hasMorePages } = pagination;
    const users = pagination.items;
    const loadUsers = pagination.loadItems;

    const modalOpen = ref(false);
    const selectedUser = ref(null);

    const showUserRegistrationModal = ref(false);
    const isEditing = ref(false);

    const openViewsModal = (user) => {
      selectedUser.value = user;
      modalOpen.value = true;
    };

    const handleModalClose = () => {
      modalOpen.value = false;
    };

    const handleModalSuccess = (message) => {
      Swal.fire({
        icon: "success",
        title: "Éxito",
        text: message,
      });
      modalOpen.value = false;
      loadUsers(currentPage.value, { search: searchTerm.value });
    };

    const openUserRegistrationModal = (editing, user = null) => {
      isEditing.value = editing;
      selectedUser.value = user;
      showUserRegistrationModal.value = true;

      nextTick(() => {
        const modalElement = document.getElementById("modalAltaUsuario");
        if (modalElement) {
          const modalInstance = Modal.getOrCreateInstance(modalElement);
          modalInstance.show();
        }
      });
    };

    const closeUserRegistrationModal = () => {
      const modalElement = document.getElementById("modalAltaUsuario");
      if (modalElement) {
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance?.hide();
      }
      showUserRegistrationModal.value = false;
    };

    const loadUsersWithSearch = (page) => {
      loadUsers(page, { search: searchTerm.value });
    };

    const debouncedLoadUsers = debounce(() => {
      loadUsers(1, { search: searchTerm.value });
    }, 300);

    watch(searchTerm, () => {
      debouncedLoadUsers();
    });

    onMounted(() => {
      loadUsers();
    });

    return {
      debouncedLoadUsers,
      loadUsersWithSearch,
      isEditing,
      searchTerm,
      users,
      currentPage,
      lastPage,
      hasMorePages,
      loadUsers,
      modalOpen,
      selectedUser,
      openViewsModal,
      handleModalClose,
      handleModalSuccess,
      showUserRegistrationModal,
      openUserRegistrationModal,
      closeUserRegistrationModal,
    };
  },
};
</script>

<style scoped>
.usuarios-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f4f4f9;
}

.header {
  background-color: #001f5b;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
}

.header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.header h2 {
  margin: 5px 0 0 0;
  font-size: 16px;
  font-weight: normal;
}

.table-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-header h2 {
  font-size: 20px;
  font-weight: bold;
  color: #001f5b;
}

.nuevo-btn {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nuevo-btn:hover {
  background-color: #0056b3;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: left;
  font-size: 14px;
}

.table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  justify-content: center;
  vertical-align: middle;
}

.options {
  display: flex;
  gap: 10px;
}

.options button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: blue;
}

.options button .fas {
  font-size: 14px;
  color: #001f5b;
}

.options button.btn-edit .fas {
  color: #007bff;
}

.options button.btn-reset .fas {
  color: #ffc107;
}

.options button.btn-delete .fas {
  color: #dc3545;
}

.no-results {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-top: 20px;
}

.table th {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}

@media (max-width: 768px) {
  .table-container {
    padding: 2rem;
  }

  .table th,
  .table td {
    font-size: 12px;
    padding: 5px;
    text-align: center;
  }

  .table th {
    white-space: nowrap;
  }

  .action-button {
    font-size: 10px;
    padding: 5px 8px;
  }

  .table-responsive {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 500px;
    border: 1px solid #ddd;
  }

  .header {
    padding: 10px;
    font-size: 18px;
  }

  .table-header h2 {
    font-size: 16px;
  }

  .nuevo-btn {
    padding: 6px 10px;
    font-size: 12px;
  }
}

.btn-new {
  margin: 0;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #0056b3;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-new:hover {
  background-color: #003d80;
  transform: translateY(-2px);
}

.btn-new:active {
  background-color: #002f63;
  transform: translateY(0);
}

.table-header {
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.table-header h2 {
  margin: 0;
  font-weight: bold;
  font-size: 1.25rem;
}

@media (max-width: 768px) {
  .btn-new {
    width: 100%;
    text-align: center;
  }

  .table-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.pagination-container {
  margin-top: 10px;
  text-align: center;
}
</style>
