import Swal from "sweetalert2";

export function useSweetAlert() {
  /**
   * @param {string} title - Título de la ventana
   * @param {string} body  - Texto principal (encabezado) en el cuerpo de la ventana
   * @param {Array} details - Información adicional en forma de [{ label, value }]
   * @param {Array} administrators - Información de administradores en forma de objetos
   */
  const showDetailsModal = (
    title,
    body,
    details = [],
    administrators = []
  ) => {
    //bloque de HTML detallado para cada administrador (si aplica)
    let adminHtml = "";
    if (administrators.length) {
      adminHtml = administrators
        .map((adm, index) => {
          return `
            <div style="margin-bottom: 1rem; border-bottom: 1px solid #ccc; padding-bottom: 1rem;">
              <h3 style="margin-bottom: 0.5rem;">Administrador ${index + 1}</h3>
              <p><strong>Nombre:</strong> ${adm.name || "No disponible"}</p>
              <p><strong>Email:</strong> ${adm.email || "No disponible"}</p>
              <p><strong>Celular:</strong> ${adm.business_phone || "No disponible"}</p>
            </div>
          `;
        })
        .join("");
    }

    // HTML para la sección "details"
    const detailsHtml = details.length
      ? `<div id="custom-details" style="margin-top: 1rem; font-size: 1rem; color: #666; text-align: left;">
          ${details
            .map(
              (detail) =>
                `<p><strong>${detail.label}:</strong> 
                  <span style="color: ${
                    detail.label === "Email" ? "#007bff" : "#666"
                  };">${detail.value}</span>
                </p>`
            )
            .join("")}
        </div>`
      : "";

    // si hay administradores 
    // Si no, mostramos el cuerpo como venía antes.
    const bodyHtml = administrators.length
      ? `
          <h2 style="font-size: 2.25rem; font-weight: bold; text-align: center; margin-bottom: 2rem; margin-top: 2rem;">
            ${body || "Información no disponible"}
          </h2>
          ${adminHtml}
        `
      : // Caso sin administradores
        details.length
        ? `<h2 style="font-size: 2.25rem; font-weight: bold; text-align: center; margin-bottom: 2rem; margin-top: 2rem;">${body ||
            "Información no disponible"}</h2>`
        : `${body || "Información no disponible"}`;

    Swal.fire({
      html: `
        <div id="custom-header">
          ${title}
        </div>
        <div id="custom-body">
          ${bodyHtml}
        </div>
        ${detailsHtml}
      `,
      showCloseButton: true,
      showConfirmButton: false,
      width: "590px",
      background: "#fff",
      customClass: {
        popup: "custom-swal-popup",
      },
      didOpen: () => {
        const htmlContainer = document.querySelector(".swal2-html-container");
        if (htmlContainer) {
          htmlContainer.style.padding = "0";
        }

        const header = document.querySelector("#custom-header");
        if (header) {
          header.style.backgroundColor = "#00a0e1";
          header.style.color = "white";
          header.style.fontSize = "1.5rem";
          header.style.fontWeight = "bold";
          header.style.textAlign = "left";
          header.style.padding = "1rem";
        }

        const bodySection = document.querySelector("#custom-body");
        if (bodySection) {
          bodySection.style.padding = "1.5rem";
          bodySection.style.fontSize = "1rem";
          bodySection.style.color = "#333";
          bodySection.style.textAlign = "left";
        }

        const detailsSection = document.querySelector("#custom-details");
        if (detailsSection) {
          detailsSection.style.padding = "1rem";
          detailsSection.style.fontSize = "0.9rem";
          detailsSection.style.color = "#666";
          detailsSection.style.textAlign = "left";
        }
      },
    });
  };

  return {
    showDetailsModal,
  };
}
