import axios from "axios";
import store from "@/store";


const instance = axios.create({
  baseURL: process.env.VUE_APP_ACARA_WEB_BACKEND_URL || "http://localhost:8000/api/v1",
  withCredentials: false,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});


instance.interceptors.request.use(
  (config) => {
    const token = store.getters.getToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      console.warn("No se encontró token de autenticación");
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      console.error("Token inválido o expirado. Redirigiendo...");
      store.commit("clearUser");
    }
    return Promise.reject(error);
  }
);

const plainInstance = axios.create({
  baseURL: process.env.VUE_APP_ACARA_WEB_BACKEND_URL || "http://localhost:8000/api/v1",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  },
});


export { instance as default, plainInstance };
