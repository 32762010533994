import axios from "@/plugins/axios";
import { plainInstance } from "@/plugins/axios"; 


const internalAxios = axios.create();

internalAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("user_access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
/**
 * Realiza la solicitud de login.
 *
 * @param {Object} credentials - Las credenciales del usuario.
 * @param {string} credentials.dni - El DNI del usuario.
 * @param {string} credentials.password - La contraseña del usuario.
 * @returns {Promise<Object>} Respuesta de la API con el token y el nombre.
 * @throws {Error} Si la solicitud falla.
 */
export async function login(credentials) {
  try {
    const response = await internalAxios.post("/login-dni", credentials);
    if (response.data) {
      return response.data;
    }

    throw new Error("Error en la respuesta del servidor.");
  } catch (error) {
    throw error.response?.data?.message || "Error al iniciar sesión.";
  }
}

/**
 * Solicita el enlace de restablecimiento de contraseña.
 *
 * @param {string} dni - El DNI del usuario.
 * @returns {Promise<Object>} Respuesta de la API.
 * @throws {Error} Si la solicitud falla.
 */
export async function sendResetLinkEmail(dni) {
  try {
    const response = await axios.post("/password/email", { dni });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || "Error al solicitar el enlace de restablecimiento.";
  }
}

export async function validateToken() {
  try {
    const response = await axios.post("/check-login");
    if (response.status === 200 && response.data) {
      return true;
    }
    return false;
  } catch (error) {
    if (error.response?.status === 401) {
      console.error("Token no válido o expirado");
    } else {
      console.error("Error al validar el token:", error);
    }
    return false;
  }
}

export async function validateTokenPlain() {
  try {
    const response = await plainInstance.post("/check-login");
    return response.data.valid;
  } catch (error) {
    console.error("Error al validar el token:", error);
    return false;
  }
}

