import { ref } from "vue";

export default function usePagination(fetchFunction) {
  const currentPage = ref(1);
  const lastPage = ref(1);
  const hasMorePages = ref(false);
  const perPage = ref(6);
  const orderBy = ref("name");
  const orderByMethod = ref("asc");
  const items = ref([]);
  const loading = ref(false);

  const loadItems = async (page = 1, extraParams = {}) => {
    loading.value = true;
    try {
      const response = await fetchFunction({
        page,
        perPage: perPage.value,
        orderBy: orderBy.value,
        orderByMethod: orderByMethod.value,
        search: extraParams.search || "",
      });
      items.value = response.data;
      currentPage.value = response.meta.current_page;
      lastPage.value = response.meta.last_page;
      hasMorePages.value = response.meta.current_page < response.meta.last_page;
    } catch (error) {
      console.error("Error al cargar datos:", error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  return {
    currentPage,
    lastPage,
    hasMorePages,
    perPage,
    orderBy,
    orderByMethod,
    items,
    loading,
    loadItems,
  };
}
