<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="step-title">PASO {{ currentStep.step }} de 5</h2>
      </div>
      <VehicleFilterButton
        :buttons="currentStep.buttons"
        @reset-filters="resetFilters"
      />
    </div>

    <ProgressBar v-if="showProgress" :progress="currentStep.progress" />

    <VehicleTypeStep
      v-if="currentStep.step === 1"
      @updateStepProgress="updateStepProgress"
      @vehicleSelected="selectedVehicle"
    />
    <VehicleBrandStep
      v-if="currentStep.step === 2"
      :brands="brands"
      @updateStepProgress="updateStepProgress"
      @brandSelected="selectedBrand"
    />
    <VehicleModelStep
      v-if="currentStep.step === 3"
      :models="models"
      @updateStepProgress="updateStepProgress"
      @modelSelected="selectedModel"
    />
    <VehicleVersionStep
      v-if="currentStep.step === 4"
      :versions="versions"
      @updateStepProgress="updateStepProgress"
      @versionSelected="selectedVersion"
    />
    <VehiclePdfStep
      v-if="currentStep.step === 5"
      :vehiculeHtml="vehiculeHtml"
      @fetchPdfList="downloadPdf"
    />
    <ImportantText />

    <div class="row">
      <div class="col-12">
        <p class="download-pdf">
          <a @click.prevent="downloadPdf" target="_blank"
            >Descargar PDF con todos los precios</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import {
  fetchBrandList,
  fetchModelList,
  fetchVersionList,
  fetchVehiculeData,
  fetchPdfList,
} from "@/services/api/price";
import { useStepNavigation } from "@/composables/prices/useStepNavigation";

import config from "@/config";
import VehicleTypeStep from "@/components/precios/steps/VehicleTypeStep.vue";
import VehicleBrandStep from "@/components/precios/steps/VehicleBrandStep.vue";
import VehicleModelStep from "@/components/precios/steps/VehicleModelStep.vue";
import VehicleVersionStep from "@/components/precios/steps/VehicleVersionStep.vue";
import VehiclePdfStep from "@/components/precios/steps/VehiclePdfStep.vue";
import VehicleFilterButton from "@/components/precios/VehicleFilterButton.vue";
import { vehicleTypes } from "@/services/vehicleTypes";
import ImportantText from "@/components/precios/ImportantText.vue";
import ProgressBar from "@/components/precios/ProgressBar.vue";

const STEP_CONFIG = [
  { step: 1, progress: 25, component: "VehicleTypeStep" },
  { step: 2, progress: 50, component: "VehicleBrandStep", props: ["brands"] },
  { step: 3, progress: 75, component: "VehicleModelStep", props: ["models"] },
  {
    step: 4,
    progress: 100,
    component: "VehicleVersionStep",
    props: ["versions"],
  },
  {
    step: 5,
    progress: 101,
    component: "VehiclePdfStep",
    props: ["vehiculeHtml"],
  },
];

export default {
  name: "StepPrecios",
  components: {
    VehicleTypeStep,
    VehicleBrandStep,
    VehicleModelStep,
    VehicleVersionStep,
    VehiclePdfStep,
    VehicleFilterButton,
    ImportantText,
    ProgressBar,
  },
  setup() {
    const { currentStep, steps, updateStepProgress } =
      useStepNavigation(STEP_CONFIG);

    const originalSteps = [
      { step: 1, progress: 25, buttons: [] },
      { step: 2, progress: 50, buttons: [] },
      { step: 3, progress: 75, buttons: [] },
      { step: 4, progress: 100, buttons: [] },
      { step: 5, progress: 101, buttons: [] },
    ];

    const showProgress = computed(() => currentStep.value.progress <= 100);

    const vehicleSelected = ref(null);
    const brandSelected = ref(null);
    const modelSelected = ref(null);
    const versionSelected = ref(null);

    const brands = ref([]);
    const models = ref([]);
    const versions = ref([]);
    const vehiculeHtml = ref("");

    const updateButtons = (key, value) => {
      const updatedButtons = [...currentStep.value.buttons];
      updatedButtons.push({ [key]: value });
      currentStep.value.buttons = updatedButtons;
    };

    const selectedVehicle = async ({ vehicle }) => {
      vehicleSelected.value = vehicle;
      updateButtons("vehicle", vehicle.name);
      await loadBrandList();
    };

    const selectedBrand = async ({ brand }) => {
      brandSelected.value = brand;
      updateButtons("brand", brand.name);
      await loadModelList();
    };

    const selectedModel = async ({ model }) => {
      modelSelected.value = model;
      updateButtons("model", model.name);
      await loadVersionList();
    };

    const selectedVersion = async ({ version }) => {
      if (isNaN(Number(version.id))) {
        versionSelected.value = null;
      } else {
        versionSelected.value = version;
        updateButtons("version", version.name);
      }
      await loadVehiculeData();
    };

    const loadBrandList = async () => {
      try {
        brands.value = await fetchBrandList(vehicleSelected.value.id);
      } catch (error) {
        console.error("Error al obtener la lista de marcas:", error);
      }
    };

    const loadModelList = async () => {
      try {
        const modelData = await fetchModelList(
          vehicleSelected.value.id,
          brandSelected.value.id
        );
        insertAllOption(modelData, "TODOS LOS MODELOS", models);
      } catch (error) {
        console.error("Error al obtener la lista de modelos:", error);
      }
    };

    const loadVersionList = async () => {
      try {
        if (isNaN(Number(modelSelected.value.id))) {
          vehiculeHtml.value = await fetchVehiculeData(
            vehicleSelected.value.id,
            brandSelected.value.id
          );
        } else {
          const versionData = await fetchVersionList(
            vehicleSelected.value.id,
            brandSelected.value.id,
            modelSelected.value.id
          );
          insertAllOption(versionData, "TODOS LAS VERSIONES", versions);
        }
      } catch (error) {
        console.error(
          "Error al obtener la lista de versiones o al descargar el PDF:",
          error
        );
      }
    };

    const loadVehiculeData = async () => {
      try {
        vehiculeHtml.value = await fetchVehiculeData(
          vehicleSelected.value.id,
          brandSelected.value.id,
          modelSelected.value.id,
          versionSelected.value?.id
        );
      } catch (error) {
        console.error("Error al obtener los datos del vehículo:", error);
      }
    };

    const insertAllOption = (data, text, target) => {
      if (data && data.data) {
        data.data.unshift({ id: "t", name: text });
        target.value = data;
      }
    };

    const openPdf = (fileName) => {
      const encodedFileName = encodeURIComponent(fileName);
      const url = `${config.backendBaseUrl}/prices/download-vehicule-processed-pdf?file=${encodedFileName}`;
      window.open(url, "_blank");
    };

    const downloadPdf = async () => {
      try {
        const validateId = (value) => {
          const id = Number(value);
          return isNaN(id) || !Number.isFinite(id) ? null : id;
        };

        const vehicleId = validateId(vehicleSelected?.value?.id);
        const brandId = validateId(brandSelected?.value?.id);
        const modelId = validateId(modelSelected?.value?.id);
        const versionId = validateId(versionSelected?.value?.id);

        if (!vehicleId) {
          openPdf("acara_precios.pdf");
          return;
        }

        if (
          vehicleId !== null &&
          brandId === null &&
          modelId === null &&
          versionId === null
        ) {
          const vehicleType = vehicleTypes.find(
            (type) => type.id === String(vehicleId)
          );

          if (vehicleType) {
            openPdf(vehicleType.file);
            return;
          } else {
            console.error(
              "No se encontró el tipo de vehículo correspondiente."
            );
          }
        }

        const response = await fetchPdfList(
          vehicleId,
          brandId,
          modelId,
          versionId
        );

        if (!response) {
          console.error(
            "Error: Respuesta no válida al intentar descargar el PDF."
          );
          return;
        }

        const blob = new Blob([response], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "precios_vehiculos.pdf");
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        link.remove();
      } catch (error) {
        console.error("Error al obtener el PDF:", error);
      }
    };

    const resetFilters = ({ index }) => {
      steps.value = JSON.parse(JSON.stringify(originalSteps));
      brands.value = [];

      switch (index) {
        case 0:
          currentStep.value.buttons = [];
          updateStepProgress({ step: 1 });
          vehicleSelected.value = null;
          brandSelected.value = null;
          modelSelected.value = null;
          versionSelected.value = null;
          break;

        case 1:
          currentStep.value.buttons = [];
          updateStepProgress({ step: 2 });
          brandSelected.value = null;
          modelSelected.value = null;
          versionSelected.value = null;
          selectedVehicle({ vehicle: vehicleSelected.value });
          break;

        case 2:
          currentStep.value.buttons = currentStep.value.buttons.filter(
            (button) => button.vehicle
          );
          updateStepProgress({ step: 3 });
          modelSelected.value = null;
          versionSelected.value = null;
          selectedBrand({ brand: brandSelected.value });
          break;

        case 3:
          currentStep.value.buttons = currentStep.value.buttons.filter(
            (button) => button.vehicle || button.brand
          );
          updateStepProgress({ step: 4 });
          versionSelected.value = null;
          selectedModel({ model: modelSelected.value });
          break;

        case 4:
          currentStep.value.buttons = currentStep.value.buttons.filter(
            (button) => button.vehicle || button.brand
          );
          updateStepProgress({ step: 5 });
          selectedVersion({ version: versionSelected.value });
          break;

        default:
          break;
      }
    };

    return {
      currentStep,
      brands,
      models,
      versions,
      vehiculeHtml,
      updateStepProgress,
      resetFilters,
      selectedVehicle,
      selectedBrand,
      selectedModel,
      selectedVersion,
      downloadPdf,
      showProgress,
    };
  },
};
</script>

<style scoped>
.step-title {
  color: #2cbad2;
  font-weight: 800;
  text-align: center;
}

.progress {
  height: 20px;
  background-color: #e9ecef;
  border-radius: 10px;
}

.progress-bar {
  background-color: #00a0e1;
}

button {
  margin: 5px;
}

.download-pdf a {
  color: #007bff !important;
  text-decoration: none !important;
  cursor: pointer;
}

.download-pdf {
  margin-top: 20px;
}

.important-text {
  margin-top: 50px;
}
</style>
