import axios from '@/plugins/axios';

export async function fetchViewByUrl() {
    try {
        const currentUrl = window.location.pathname;
        
        const identifier = currentUrl === '/' ? 'index' : encodeURIComponent(currentUrl.substring(1));

        const response = await axios.get(`/views/${identifier}`);
        
        return response.data;
    } catch (error) {
        console.error("Error al obtener la vista:", error);
        throw error;
    }
}


export async function fetchViewsWithPrivacy(params = {}) {
    try {
        const response = await axios.get('/views', {
            params: {
                ...params,
                privacy: 1,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error al obtener las vistas con privacidad 1:", error);
        throw error;
    }
}

export async function fetchAllViews(params = {}) {
    try {
        const response = await axios.get('/views', { params });
        return response.data?.data || [];
    } catch (error) {
        console.error("Error al obtener todas las vistas:", error);
        throw error;
    }
}

